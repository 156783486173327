import React, { Component } from "react"

import Layout from "../components/layout"
import SearchAll from "../components/searchAll"
import SEO from "../components/seo"
import { Link } from "gatsby"

import "../components/style.css"
export class UnitedStates extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO
            title={`Facebook Marketplace United States`}
            description={`Search all of United States`}
            keywords={`All, results, by country, Facebook, United States, USA, America`}
            placename={"United States"}
          />
          <div className="container">
            <div className="centered">
              <div className="style1">
                <h1 className="style1">Search all of United States</h1>
                <div> Items for Sale </div>
              </div>
              <div className="style2">Remove the 100-mile boundary</div>
              <Link to="/about">
                <input type="submit" value="Learn More" className="button1" />
              </Link>
            </div>
          </div>

          <Link style={{ textDecoration: "none" }} to="/">
            {" "}
            <h1>Country Selected: United States </h1>
          </Link>
          <SearchAll
            placeName={[
              "108061882547758",
              "boise",
              "sanfrancisco",
              "108013345886344",
              "dc",
            ]}
          />
        </Layout>
      </>
    )
  }
}

export default UnitedStates
